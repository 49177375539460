@import 'variables.scss';
@import 'fonts.scss';
@import 'bootstrap.scss';
@import '~bootstrap/scss/_modal';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  font-size: 16px;
}
.btn {
  &[disabled] {
    cursor: not-allowed;
  }
}
.btn-gray {
  height: 42px;
  font-size: 16px;
  font-weight: bold;
  color: #fff !important;
  background: rgba(#cdcdcd, 0.8);
  border: 0;
  border-radius: 5px;
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background: $primary;
  }
  &.active {
    background: #aaa !important;
    box-shadow: none;
  }
}

.min-width-120 {
  min-width: 120px !important;
}
.max-width-620 {
  @media only screen and (min-width: 768px) {
    max-width: 620px !important;
  }
}
.form-control,
.custom-select {
  height: 40px;
  color: #4f4f4f;
  border: 0;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  input:-ms-input-placeholder {
    color: #8e8e8e;
  }
  input:-moz-placeholder {
    color: #8e8e8e;
  }
  input::-moz-placeholder {
    color: #8e8e8e;
  }
  input::-webkit-input-placeholder {
    color: #8e8e8e;
  }
}
.custom-select,
.custom-control-label {
  cursor: pointer;
}
.custom-select {
  background: rgba(255, 255, 255, 0.9)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAABHNCSVQICAgIfAhkiAAAAG5JREFUCFttjrENgDAMBP2ZiBEYgRFoo1RskBFoIreMkA1YgYls5AijEOHKrz+dDWauIpJTShf9TCllCiFk2AKgquoywn0HkzDzSkS7iMwOP9AJYIsxHg0cYcsAXqjl/i03q6q6yfsP2JnJzvWSG9dUO2sq2TmjAAAAAElFTkSuQmCC)
    no-repeat right 0.75rem center;
}
.custom-control-input {
  cursor: pointer;
  z-index: 1;
}
.background-opacity {
  background-color: rgba(0, 0, 0, 0.4);
}
.card {
  border: none;
}
.btn-back {
  height: auto;
  border-radius: 5px;
  background: rgba(205, 205, 205, 0.25);
  svg {
    height: 15px;
  }
}

.react-datepicker {
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: none;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      border: 1px solid #ddd;
      font-size: 14px;
      height: 35px;
      padding: 0.375rem 0.75rem;
      width: 100%;
      color: #495057;
      border-radius: 5px;
      &:focus {
        outline: 0;
      }
      &[disabled],
      &[readonly] {
        background-color: #e9ecef;
        border-color: #ced4da;
      }
    }
  }
  .react-datepicker__month-container {
    float: left;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: -1px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 34px;
    line-height: 25px;
  }
  .react-datepicker__day-name {
    color: #333;
    font-weight: 600;
    font-size: 16px;
  }
  .react-datepicker__header {
    border-bottom: 1px solid #ddd;
    background: #fff;
    select {
      word-wrap: normal;
      border: 0;
      font-size: 16px;
      color: #333;
      outline: none;
      position: relative;
      &:first-child {
        content: '';
        position: absolute;
        background: #ddd;
        height: 15px;
        width: 1px;
      }
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name,
  .react-datepicker__input-time-container {
    font-size: 16px;
  }
  .react-datepicker__triangle {
    border-bottom-color: white !important;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    width: 175px;
  }
  &.is-invalid {
    .react-datepicker-wrapper {
      input {
        border-color: #dc3545;
      }
    }
  }
  .next-date {
    font-size: 15px;
    color: rgb(158, 158, 158);
    border: 0;
    background: none;
    outline: 0;
    text-align: center;
    cursor: pointer;
    padding: 3px 10px 0;
    i {
      color: #333;
    }
  }
}

.select-date {
  width: 230px;
  margin: 0 auto 15px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.modal-content {
  margin-top: 50%;
}

.react-select {
  > * {
    width: 100%;
  }
  .select-box__indicator-separator {
    display: none;
  }
  .select-box__control {
    height: 35px;
    border-color: #e4ecf0;
    min-height: 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwICIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIiBjb2xvcj0iIzAwMDAwMCI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMzEuMyAxOTJoMjU3LjNjMTcuOCAwIDI2LjcgMjEuNSAxNC4xIDM0LjFMMTc0LjEgMzU0LjhjLTcuOCA3LjgtMjAuNSA3LjgtMjguMyAwTDE3LjIgMjI2LjFDNC42IDIxMy41IDEzLjUgMTkyIDMxLjMgMTkyeiI+PC9wYXRoPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 8px auto;
    &:hover {
      border-color: rgb(232, 232, 232);
    }
  }
  .select-box__single-value {
    top: 45%;
    max-width: calc(100% - 30px);
  }
  .select-box__indicator {
    padding: 4px;
    padding-right: 8px;
    color: #000 !important;
  }
  .select-box__clear-indicator {
    padding-right: 4px;
    svg {
      width: 14px;
    }
  }
  &:active {
    border-color: rgb(232, 232, 232);
  }
  .select-box__placeholder {
    color: #95989a;
    top: 45%;
  }
  &.is-invalid {
    .select-box__control {
      border-color: #dc3545;
    }
  }
  .no_result {
    text-align: left;
    color: #000 !important;
  }
  .select-box--is-disabled {
    .select-box__control {
      background-color: #e9ecef;
      border-color: #ced4da;
    }
  }
  .select-box__menu {
    z-index: 999;
  }
}
